body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #3B95E3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* NAVBAR */
#title {
  margin-top: 15px;
  margin-bottom: 0px;
  padding: 10px 10px 0px 10px;
  font-size: 35px;
  color: #fc3903;
  background-color: white;
  font-family: Monaco, serif;
  text-align: center;
  justify-content: flex-end;
}

#hamburger-icon {
  position: absolute;
  top: 25px;
  right: 15px;
  cursor: pointer;

}

#dropdown-items {
  font-size: 15px;
  color: #3B95E3;
  font-weight: 900;
}

#small-header {
  color: #3B95E3;
  font-size: 18px;
  font-style: italic;
}


/* HOMEPAGE */
#about-me-container {
  margin: 15px 15px 0px 15px;
  width: 50%;
  background-color: #fc3903;
  border-radius: 10px;
  box-shadow: 0 1px 3px 0 white, 0 0 0 1px white;
}

#about-me {
  color: white;
  font-size: 15px;
  font-family: Monaco, serif;
  font-weight: 900;
  padding: 20px;
  margin-top: 15px;
}

/* Listening to link effects */
/* unvisited link */
#listening-to:link {
  color: white;
  text-decoration: underline;
}

/* visited link */
#listening-to:visited {
  color: white;
  text-decoration: underline;
}

/* mouse over link */
#listening-to:hover {
  color: #3B95E3;
  text-decoration: underline;
}

/* selected link */
#listening-to:active {
  color: #3B95E3;
  text-decoration: underline;
}

#leah-pic-container {
  display: flex;
  justify-content: flex-end;
}

#leah-pic {
  margin: 15px 15px 15px 15px;
  width: 48%;
  border-radius: 10px;
  box-shadow: 0 1px 3px 0 white, 0 0 0 1px white;
}


/* PORTFOLIO */
#project-cards-container {
  margin: 5px 15px 5px 15px;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  gap: 1em;
  max-width: 100%;
}

#project-cards {
  /* width: 300px; */
  display: flex;
  flex-direction: column;
  text-align: center;
}

.content-container {
  flex: 1;
  padding: 1em;
}

#project-links-container {
  flex: 1;
  padding: 1em;
  text-align: center;
}

.project-links {
  color: #3B95E3;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 15px 0px;
  font-weight: 600;
}

#tech-stack-container {
  display: flex;
  align-items: right;
  justify-content: space-around;
}

.tech-icons {
  height: 50px;
  width: 50px;
}


/* RESUMES */
#resumes-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 15px;
  padding-bottom: 10px;
}


/* CONTACT */
#leah-pic-container-contact {
  display: flex;
  justify-content: center;
}

#contact-heading {
  color: white;
  font-family: Monaco, serif;
  text-align: center;
  font-weight: 900;
  font-size: 18px;
  font-style: italic;
}


/* CONTACT-LINKS */
#contact-link-icons {
  text-align: center;
  color: white;
  position: absolute;
  bottom: 5px;
  width: 100%;
  padding-top: 10px;
  height: 35px;
  /* justify-items: center; */
}

#contact-link-icons i {
  margin-right: 15px;
  margin-left: 15px;
}

/* contact icon link effects */
/* unvisited link */
#contact:link {
  color: white;
}

/* visited link */
#contact:visited {
  color: white;
}

/* mouse over link */
#contact:hover {
  color: #fc3903;
}

/* selected link */
#contact:active {
  color: #fc3903;
}

.custom-icon {
  transform: scale(2);
}

::-webkit-scrollbar {
  display: none;
}

/* NO PAGE */
#no-page-container {
  display: flex; 
  justify-content: center; 
  border-radius: 30px;
}

#no-page-box {
  color: white; 
  background-color: #fc3903; 
  font-weight: 700;
  text-align: center; 
  margin: 15px;
  width: 50%;
  padding: 25px 50px 30px 50px; 
  border-radius: 30px; 
  box-shadow: 0 1px 3px 0 white, 0 0 0 1px white;
  font-family: Monaco, serif;
}

#no-page-gif-container {
  display: flex; 
  justify-content: center;
}

#no-page-gif {
  max-width: 100%;
  max-height: 100%;
  width: auto; 
  height: auto; 
  border-radius: 30px;
}